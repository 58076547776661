body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.cs-conversation__unread-dot {
  margin-left: 0.5em;
}

.cs-message--incoming .cs-message__content .qwert{
  border-color: brown;
}

.cs-conversation__content {
  max-width: 50%;
}

.cs-conversation__last-activity-time {
  white-space: nowrap;
}

@media (max-width: 900px) {
  .cs-main-container--responsive .cs-conversation-list .cs-conversation__last-activity-time,
  .cs-main-container--responsive .cs-sidebar .cs-conversation-list .cs-conversation__last-activity-time {
    display: flex !important;
  }

  .cs-main-container--responsive .cs-chat-container .cs-conversation-header .cs-conversation-header__back {
    display: flex !important;
  }

  .cs-main-container--responsive .cs-search {
    display: flex !important;
  }
}